import React, { useState } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { useNavigate } from '@reach/router'

import Order from '../../components/styled/Order'
import OrderProductList from '../../components/OrderProductList'
import Price from '../../components/Price'
import ShowDate from '../../components/ShowDate'

import useOrders from '../../hooks/useOrders'
import Button from '../../components/styled/Button'
import Field from '../../components/styled/Field'
import StoreLayout from '../../components/StoreLayout'

const Ready = styled.span`
    background-color: ${props => props.theme.brandColor1};
    border: solid 2px ${props => props.theme.brandColor1};
    display: inline-block;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
`

const Making = styled.span`
    border: solid 2px ${props => props.theme.gray};
    display: inline-block;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
`

const Status = ({ complete }) => {
    return complete ? (
        <Ready>Listo para recoger</Ready>
    ) : (
        <Making>En preparación</Making>
    )
}

const getEmailFromHash = hash =>
    hash
        .split('/')
        .filter(item => item !== '#')
        .join('')

const Content = ({ store, email }) => {
    const { orders, loading, error } = useOrders({
        store,
        email,
    })
    if (error) {
        return (
            <Order.EmptyState>Error: {JSON.stringify(error)}</Order.EmptyState>
        )
    }

    if (loading) {
        return <Order.EmptyState>Cargando...</Order.EmptyState>
    }

    return (
        <>
            {orders.length ? (
                <Order.Wrapper>
                    {orders.map((order, i) => {
                        const date = order.createdAt.toDate()
                        return (
                            <Order.Item key={i}>
                                <Order.Header>
                                    <h3>
                                        <ShowDate date={date} type="dateTime" />
                                    </h3>
                                    <Status complete={order.complete} />
                                </Order.Header>

                                <OrderProductList items={order.items} />

                                <Order.Footer>
                                    <h4>
                                        Total: <Price>{order.total}</Price>
                                    </h4>
                                </Order.Footer>
                            </Order.Item>
                        )
                    })}
                </Order.Wrapper>
            ) : (
                <Order.EmptyState>No hay ordernes pendientes</Order.EmptyState>
            )}
        </>
    )
}

const handleFocus = e => {
    e.target.parentNode.classList.add('focus')
}

const handleBlur = e => {
    e.target.parentNode.classList.remove('focus')
}

const getLabelClass = valid => (valid ? 'required invalid' : 'required')

const GetEmail = ({ store }) => {
    const initialValue = {
        email: '',
    }

    const initialValidity = {
        email: false,
    }

    const [formValue, setFormValue] = useState(initialValue)
    const [validity, setValidity] = useState(initialValidity)
    const navigate = useNavigate()

    const onChangeHandler = e => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value,
        })
    }

    return (
        <form
            onSubmit={e => {
                e.preventDefault()
                navigate(`#/${formValue.email}`)
            }}
            onInput={e => {
                if (e.target.checkValidity) {
                    setValidity({
                        ...validity,
                        [e.target.id]: !e.target.checkValidity(),
                        form: e.currentTarget.checkValidity(),
                    })
                }
            }}
        >
            <Field htmlFor="name" className={getLabelClass(validity.email)}>
                <span>Ingresa tu email</span>
                <input
                    autoComplete="email"
                    id="email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={onChangeHandler}
                    onFocus={handleFocus}
                    required
                    type="email"
                    value={formValue.email}
                />
            </Field>
            <Button type="submit" disabled={!validity.form}>
                Enviar
            </Button>
        </form>
    )
}

const MisPedidos = ({ location }) => {
    const email = getEmailFromHash(location.hash)
    const store = 'emerson'

    return (
        <StoreLayout
            location={location}
            seourl={location.pathname}
            seotitle="Convenience Store Mini Market Tienda de Conveniencia"
        >
            <Helmet
                htmlAttributes={{
                    class: store,
                }}
            />
            <Order.Main>
                <h1>Mis Pedidos</h1>

                {email ? (
                    <Content store={store} email={email} />
                ) : (
                    <GetEmail store={store} />
                )}
            </Order.Main>
        </StoreLayout>
    )
}

export default MisPedidos
